

















































































































import { Component, Vue } from 'vue-property-decorator'
import Loader from '@bertazzoni/common/src/components/Loader.vue'
import DatePicker from '@bertazzoni/back/components/DatePicker.vue'
import StatisticView from '@bertazzoni/back/components/StatisticView.vue'
import KeyElementsView from '@bertazzoni/back/components/KeyElementsView.vue'
import PaginatedTable from '@bertazzoni/back/components/PaginatedTable.vue'
import TagList from '@bertazzoni/back/components/TagList.vue'
import ExportModule from '@bertazzoni/back/components/ExportModule.vue'
import AccessFilter from '@bertazzoni/back/components/AccessFilter.vue'
import ModulesService from '@bertazzoni/common/src/services/ModulesService'
import ContentService from '@bertazzoni/common/src/services/ContentService'
import StatsService from '@bertazzoni/common/src/services/StatsService'
import { Mod } from '@bertazzoni/common/src/models/module.model'
import { Content } from '@bertazzoni/common/src/models/content.model'
import { TableInfo } from '@bertazzoni/back/models/table.model'
import EventBus from '@bertazzoni/common/src/helpers/eventBus'

@Component({
  components: {
    Loader,
    DatePicker,
    StatisticView,
    KeyElementsView,
    PaginatedTable,
    AccessFilter,
    TagList,
    ExportModule
  }
})
export default class ModuleConsultation extends Vue {
  private currentModule: Mod = new Mod()
  private contentlist: Content[] = []
  private pageLoaded = false
  private country = ''
  private moduleStat = { completed: 0, avgScore: 0 }
  private tableInfoModule: TableInfo = new TableInfo()
  private tableInfoQuiz: TableInfo = new TableInfo()

  async created(): Promise<void> {
    this.setTableInfo()
    this.currentModule = await ModulesService.findOne(this.$route.params.id)
    this.country = this.getCountry()
    await this.getStats()
    await this.getContentList()
    this.pageLoaded = true
  }
  async getStats(): Promise<void> {
    try {
      this.moduleStat.completed = await StatsService.moduleCompleted(this.$route.params.id)
      this.moduleStat.avgScore = await StatsService.moduleAvgScore(this.$route.params.id)
    } catch {
      this.moduleStat.completed = 0
      this.moduleStat.avgScore = 0
      EventBus.$emit('close', 'all')
    }
  }
  getCountry(): string {
    return this.currentModule.accessEntityIds[0]
  }
  async setTableInfo(): Promise<void> {
    this.tableInfoModule.getCustomTable().isPaginated = false
    this.tableInfoQuiz.getCustomTable().isPaginated = false
    this.tableInfoQuiz.getCustomTable().displayButton = false
  }
  async getContentList(): Promise<void> {
    this.contentlist = await ContentService.findByIds(this.currentModule.contentIDs)
    await this.mappingContentViewStats()
  }
  private async mappingContentViewStats(): Promise<void> {
    const contentsAndView = await ContentService.findContentsViewsFromModule(this.currentModule._id)
    this.contentlist.map((content) => {
      return contentsAndView.filter((c) => {
        if (c.contentId == content._id) {
          return (content.views = c.views)
        }
      })
    })
  }
  goStat(): void {
    this.$router.push(`/module-stats/${this.$route.params.id}`)
  }
}
