

























import { Component, Prop, Vue } from 'vue-property-decorator'
import Icons from '@bertazzoni/common/src/components/Icons.vue'
@Component({
  components: { Icons }
})
export default class StatisticView extends Vue {
  @Prop({ required: true }) subscribers!: number
  @Prop({ required: true }) avgScore!: number

  seeMore(): void {
    this.$emit('see-more')
  }
}
