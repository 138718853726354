





















import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { Content } from '@bertazzoni/common/src/models/content.model'
import JSZip from 'jszip'
import FileSaver from 'file-saver'

type ZipContent = {
  title: string
  src: any
  type: string
}

@Component
export default class ExportModule extends Vue {
  @Prop({ required: true }) private readonly contentList!: Partial<Content[]>
  @Prop({ required: true }) private readonly moduleName!: string

  //   private readonly formatList: string[] = ['.zip', '.scorm'] // todo when
  private readonly formatList: string[] = ['.zip']

  private contentsZiped: ZipContent[] = null
  private exportFormat = ''

  private created(): void {
    this.contentsZiped = this.contentList.map((content) => {
      return {
        title: content.title,
        src: content.srcURI,
        type: content.type
      }
    })
  }

  @Watch('exportFormat')
  private onChangeFormat(newValue: string) {
    newValue === '.zip' ? this.handleZipExport() : this.handleScormExport()
  }

  private handleZipExport(): void {
    this.generateZipFileFrom()
  }
  private handleScormExport(): void {
    console.log('export scorm')
  }

  private generateZipFileFrom(): any {
    const zip = new JSZip()
    const fileZipedName = this.moduleName

    const transformAndPushContentToFile = (content) =>
      zip.file(`${content.title}.${content.type == 'VIDEO' ? 'mp4' : 'ppt'}`, `${content.src}`)
    this.contentsZiped.forEach((content) => transformAndPushContentToFile(content))

    zip.generateAsync({ type: 'blob' }).then(function(content) {
      FileSaver.saveAs(content, `${fileZipedName}.zip`)
    })
  }
}
